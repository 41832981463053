import Marquee from "react-fast-marquee";
import NextImage, { StaticImageData } from "next/image";
import FeaturedSection from "./FeaturedSection";

import BloombergMarquee from "public/landing-marquee-featured-in/bloomberg.png";
import BusinessInsiderMarquee from "public/landing-marquee-featured-in/business-insider.png";
import DallasMorningNewsMarquee from "public/landing-marquee-featured-in/dallas-morning-news.png";
import DeloitteMarquee from "public/landing-marquee-featured-in/deloitte.png";
import HoustonChronicleMarquee from "public/landing-marquee-featured-in/houston-chronicle.png";
import TechCrunchMarquee from "public/landing-marquee-featured-in/tech-crunch.png";
import USATodayMarquee from "public/landing-marquee-featured-in/usa-today.png";
import WSJMarquee from "public/landing-marquee-featured-in/wall-street-journal.png";
import YahooFinanceMarquee from "public/landing-marquee-featured-in/yahoo-finance.png";

import WhiteABCSVG from "public/marquee-featured-in/white-ABC.svg";
import WhiteBloombergSVG from "public/marquee-featured-in/white-Bloomberg.svg";
import WhiteBusinessInsiderSVG from "public/marquee-featured-in/white-Business-Insider.svg";
import WhiteDeloitteSVG from "public/marquee-featured-in/white-Deloitte.svg";
import WhiteForbesSVG from "public/marquee-featured-in/white-Forbes.svg";
import WhiteMOneySVG from "public/marquee-featured-in/white-MOney.svg";
import WhiteTechcrunchSVG from "public/marquee-featured-in/white-Techcrunch.svg";
import WhiteUSATodaySVG from "public/marquee-featured-in/white-USA-Today.svg";
import WhiteWSJSVG from "public/marquee-featured-in/white-WSJ.svg";

export const WhiteMarqueeLogos = {
	ABC: {
		SVG: WhiteABCSVG,
		alt: "ABC Logo",
	},
	BLOOMBERG: {
		SVG: WhiteBloombergSVG,
		alt: "Bloomberg Logo",
	},
	BUSINESS_INSIDER: {
		SVG: WhiteBusinessInsiderSVG,
		alt: "Business Insider Logo",
	},
	DELLOITTE: {
		SVG: WhiteDeloitteSVG,
		alt: "Deloitte Logo",
	},
	FORBES: {
		SVG: WhiteForbesSVG,
		alt: "Forbes Logo",
	},
	MONEY: {
		SVG: WhiteMOneySVG,
		alt: "Money Logo",
	},
	TECHCRUNCH: {
		SVG: WhiteTechcrunchSVG,
		alt: "Tech Crunch Logo",
	},
	USA_TODAY: {
		SVG: WhiteUSATodaySVG,
		alt: "USA Today Logo",
	},
	WALL_STREET_JOURNAL: {
		SVG: WhiteWSJSVG,
		alt: "Wall Street Journal Logo",
	},
};

export const CardsWhiteSVGTransparentBackground = Object.values(
	WhiteMarqueeLogos
).map(({ SVG, alt }, i) => (
	<SVG
		key={i}
		alt={alt}
		className="marquee-featured-card-white-transparent-background"
	/>
));

export const MarqueeLogos = {
	BLOOMBERG: {
		img: BloombergMarquee,
		alt: "Bloomberg Logo",
	},
	BUSINESS_INSIDER: {
		img: BusinessInsiderMarquee,
		alt: "Business Insider Logo",
	},
	DALLAS_MORNING_NEWS: {
		img: DallasMorningNewsMarquee,
		alt: "Dallas Morning News Logo",
	},
	DELOITTE: {
		img: DeloitteMarquee,
		alt: "Deloitte Logo",
	},
	HOUSTON_CHRONICLE: {
		img: HoustonChronicleMarquee,
		alt: "Houston Chronicle Logo",
	},
	TECH_CRUNCH: {
		img: TechCrunchMarquee,
		alt: "Tech Crunch Logo",
	},
	USA_TODAY: {
		img: USATodayMarquee,
		alt: "USA Today Logo",
	},
	WALL_STREET_JOURNAL: {
		img: WSJMarquee,
		alt: "Wall Street Journal Logo",
	},
	YAHOO_FINANCE: {
		img: YahooFinanceMarquee,
		alt: "Yahoo Finance Logo",
	},
};

type MarqueeLogo = {
	img: StaticImageData | string;
	alt: string;
};

export const MarqueeFeaturedSection = ({
	title,
	cardClassName,
	logos,
	className,
	labelClassName,
	customCards,
}: {
	title?: string;
	cardClassName?: string;
	logos?: MarqueeLogo[];
	className?: string;
	labelClassName?: string;
	customCards?: React.ReactNode[];
}) => {
	const cards = (logos ? logos : Object.values(MarqueeLogos)).map(
		({ img, alt }, i) => {
			return (
				<div key={i} className={cardClassName ?? "marquee-featured-logos-img"}>
					<NextImage
						priority
						quality={100}
						src={img}
						alt={alt}
						style={{
							maxWidth: "100%",
							height: "auto",
						}}
					/>
				</div>
			);
		}
	);

	return (
		<div id="landing-marquee-section" className={className}>
			<p className={labelClassName ?? "bold marquee-featured-in uppercase"}>
				{title ? title : "As seen on"}
			</p>
			<Marquee speed={25}>
				{customCards ?? cards}
				{/* duplicate content for wide screens */}
				{customCards ?? cards}
			</Marquee>
		</div>
	);
};
